// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDEfw-3eFkh2P-R3rp2424Nd1KGG1hxm24",
  authDomain: "traffic-app-5e558.firebaseapp.com",
  projectId: "traffic-app-5e558",
  storageBucket: "traffic-app-5e558.firebasestorage.app",
  messagingSenderId: "853889097612",
  appId: "1:853889097612:web:c62c3905e64ca799f380a9",
  measurementId: "G-FHS16EH0Y5",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

// const analytics = getAnalytics(app);
// apiKey: "AIzaSyDEfw-3eFkh2P-R3rp2424Nd1KGG1hxm24",
// authDomain: "traffic-app-5e558.firebaseapp.com",
// projectId: "traffic-app-5e558",
// storageBucket: "traffic-app-5e558.appspot.com",
// messagingSenderId: "853889097612",
// appId: "1:853889097612:web:c62c3905e64ca799f380a9",
// measurementId: "G-FHS16EH0Y5",
