import React, { useState, useEffect } from "react";
import {
  getFirestore,
  doc,
  setDoc,
  collection,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import ReactDOM from "react-dom";
import { app } from "../../../firebase/firebase";
import FormSubmitted from "../FormSubmitted";
import "../form.css";

import { useAuth } from "../../../firebase/useAuth";

const UpdateCarData = ({ data, onXClick, carName }) => {
  const db = getFirestore(app);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const fieldTypes = {
    year: "number",
    vin: "text",
    enginePower: "number",
    registrationDate: "date",
    lastInspectionDate: "date",
    mileage: "number",
    numberOfDoors: "number",
    engineNumber: "text",
    policyNumber: "number",
    policyExpiryDate: "date",
    warrantyNumber: "number",
    warrantyExpiryNumber: "date",
  };

  // Set the initial state with data received as a prop
  const [formData, setFormData] = useState({
    manufacturer: data.manufacturer || "",
    model: data.model || "",
    year: data.year || "",
    vin: data.vin || "",
    licensePlate: data.licensePlate || "",
    enginePower: data.enginePower || "",
    registrationDate: data.registrationDate || "",
    lastInspectionDate: data.lastInspectionDate || "",
    mileage: data.mileage || "",
    fuelType: data.fuelType || "",
    numberOfDoors: data.numberOfDoors || "",
    transmissionType: data.transmissionType || "",
    color: data.color || "",
    engineNumber: data.engineNumber || "",
    ownershipStatus: data.ownershipStatus || "",
    insuranceCompany: data.insuranceCompany || "",
    policyNumber: data.policyNumber || "",
    policyExpiryDate: data.policyExpiryDate || "",
    supplierCompany: data.supplierCompany || "",
    warrantyNumber: data.warrantyNumber || "",
    warrantyExpiryNumber: data.warrantyExpiryNumber || "",
    selectedDriver: data.selectedDriver || "",
  });
  const translateLabel = (label) => {
    const translations = {
      manufacturer: "Proizvođač",
      model: "Model",
      year: "Godina",
      vin: "Broj Šasije",
      licensePlate: "Registarski broj",
      enginePower: "Snaga motora",
      registrationDate: "Datum registracije",
      lastInspectionDate: "Datum poslednjeg pregleda",
      mileage: "Kilometraža",
      fuelType: "Tip goriva",
      numberOfDoors: "Broj vrata",
      transmissionType: "Tip prenosa",
      color: "Boja",
      engineNumber: "Broj motora",
      ownershipStatus: "Status vlasništva",
      insuranceInfo: "Podaci o osiguranju",
      serviceHistory: "Istorija servisa",
      selectedDriver: "Izabrani vozač",
      insuranceCompany: "Osiguravajuća kuća",
      policyNumber: "Broj polise",
      policyExpiryDate: "Datum isteka polise",
      supplierCompany: "Dobavljačka firma",
      warrantyNumber: "Broj garancije",
      warrantyExpiryNumber: "Datum isteka garancije",
    };

    return translations[label] || label;
  };
  const isFieldEditable = (fieldName) => {
    // List of non-editable fields
    const nonEditableFields = ["manufacturer", "model", "licensePlate"];
    // Check if the field is in the non-editable list
    return !nonEditableFields.includes(fieldName);
  };
  // State for storing the list of drivers from Firebase
  const [drivers, setDrivers] = useState([]);

  useEffect(() => {
    // Fetch the list of drivers when the component mounts
    const fetchDrivers = async () => {
      const driversCollection = collection(db, "drivers");

      try {
        const driverQuerySnapshot = await getDocs(driversCollection);
        const driverList = driverQuerySnapshot.docs.map((driverDoc) => ({
          id: driverDoc.id,
          ...driverDoc.data(),
        }));
        setDrivers(driverList);
      } catch (error) {
        console.error("Error fetching drivers: ", error);
      }
    };

    fetchDrivers();
  }, [db]);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Generate a unique ID for the car
    // Create an object with form data and selected driver
    const carData = {
      id: data.id,
      ...formData,
    };
    console.log(carData);

    const carDocRef = doc(db, "cars", carName, "info", `car`);

    try {
      // Set the data with the specified document ID (licensePlate)
      await setDoc(carDocRef, carData);
      window.location.reload();
      console.log("Document successfully written!");

      // Reset the form data object
      setFormData({
        ...formData,
        selectedDriver: "", // Reset the selected driver
      });
      setFormSubmitted(true);
      window.location.reload();

      // Inform the parent that editing is complete
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  // Function to handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const modalContent = (
    <div className="custom-form-container modal-edit">
      <h2 style={{ paddingBottom: "20px" }}>Uredi Vozilo</h2>
      <form onSubmit={handleSubmit} className="custom-form">
        {Object.keys(formData).map(
          (key) =>
            // Only render editable fields
            isFieldEditable(key) && (
              <div key={key} className="custom-form-group">
                <label htmlFor={key} className="custom-label">
                  {translateLabel(key)}
                </label>
                <input
                  type={fieldTypes[key] || "text"}
                  name={key}
                  value={formData[key]}
                  onChange={handleChange}
                  className="custom-input"
                  // Disable the input field if it's not editable
                  disabled={!isFieldEditable(key)}
                />
              </div>
            )
        )}
        <div className="custom-form-group">
          <label htmlFor="" className="custom-label">
            Driver:
          </label>
          <select
            name="selectedDriver"
            value={formData.selectedDriver}
            onChange={handleChange}
            className="custom-select"
          >
            <option value="">Select a driver</option>
            {drivers.map((driver) => (
              <option key={driver.id} value={driver.id}>
                {driver.driverName}
              </option>
            ))}
          </select>
          {error && (
            <div className="error-message">All fields must be filled!</div>
          )}
          <button
            style={{ marginBottom: "20px" }}
            type="submit"
            className="custom-submit-button"
          >
            Ažuriraj
          </button>
          <button
            style={{
              position: "relative",
              left: "10px",
              marginBottom: "20px",
              borderRadius: "3px",
            }}
            className="deleteBtn"
            onClick={onXClick}
          >
            Odustani
          </button>
          <p style={{ color: "#f7f7f7" }}>akdjs</p>
          {/* <p>asd</p> */}
        </div>
      </form>
    </div>
  );
  const appContainer = document.querySelector(".App");

  if (!appContainer) {
    console.error("Target container not found");
    return null;
  }

  // Use ReactDOM.createPortal after checking the target container
  return ReactDOM.createPortal(modalContent, appContainer);
};

export default UpdateCarData;
